#review {
  background: rgb(221, 221, 221);
}

.review-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.review-card {
  width: 100%;
  height: 100%;
}

.review-carousel {
  width: 80%;
  position: relative; 
}

.review-card img {
  float: left;
  margin-right: 10px;
}

.review-card .card-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Adjust Indicators */
.carousel-indicators {
  position: absolute;
  bottom: -22px !important; 
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  z-index: 3; 
}

.carousel-indicators li {
  width: 12px;
  height: 12px;
  background-color: #fffefe; 
  border-radius: 50%; 
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.carousel-indicators .active {
  background-color: rgb(231, 121, 55); 
}

/* Adjust Navigation Buttons */
.carousel-control-prev,
.carousel-control-next {
  width: 5%; /* Reduce the width of controls */
  z-index: 3; /* Ensure they stay above the slides */
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: rgba(0, 0, 0, 0.5); /* Add background for better visibility */
  border-radius: 50%; /* Circular control icons */
  padding: 10px; /* Add padding for better appearance */
}

.carousel-control-prev:hover .carousel-control-prev-icon,
.carousel-control-next:hover .carousel-control-next-icon {
  background-color: rgb(231, 121, 55); /* Add hover effect */
}

/* Media Queries */
@media (max-width: 768px) {
  .review-carousel {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .review-carousel {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .carousel-indicators {
    bottom: 10px; /* Adjust for smaller screens */
  }

  .carousel-indicators li {
    width: 10px;
    height: 10px; /* Smaller indicators on small screens */
  }
}



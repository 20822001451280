body {
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.table th,
.table td {
    text-align: center;
    vertical-align: middle;
    font-family: "Margarine, calibri";
    font-weight: 100;
}
.Download-course-btn {
  background: rgb(231, 121, 55);
  padding: 0.5rem;
  margin: 2rem;
  color: white;
  border: none;
}
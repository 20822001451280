.logo-slider {
    padding: 20px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }
  
  .logo-slide img {
    width: 25%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  .logo-slider .slick-track{
    display: flex;
    align-items: center;
  }
  

.navbar{
  background-color: #fff;
  
}
.navbar .nav-link {
  color: #000;
}

.navbar .nav-link:hover,
.navbar .nav-link:active {
  color: rgb(231, 121, 55);
}
.book-call-btn{
  background: rgb(231, 121, 55);
  border: none;
  color: white;
  width: 17rem;
  transition: transform 0.3s ease, background-color 0.3s ease;
  border-radius: 8px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}
.book-call-btn:hover {
  transform: scale(1.1); 
  background: rgb(255, 140, 75); 
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.3);
}
.book-call-btn:active {
  transform: scale(1); /* Resets the size to normal */
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.2); /* Reduces shadow when clicked */
}
.accordion {
    .accordion-item {
        position: relative;
        border: 0;
        background: transparent;

        &:not(:first-child) {
            border-top: 1px solid #ddd;
        }
    }

    .accordion-button {
        transition: all 0.3s;
        position: relative;
        background-color: transparent;
        padding: 1.5rem;
        font-size: 1.25rem;
        font-weight: bold;
        box-shadow: none;
        color: #000;

        &:not(.collapsed) {
            color: rgb(231, 121, 55);
        }

        .row {
            flex: 1 1 auto;
        }

        &::after {
            transition: transform 0.3s;
            position: absolute;
            top: 50%;
            right: 0;
            /* content: "\f078"; FontAwesome icon for caret-down */
            font-family: 'FontAwesome';
            font-size: 1rem;
            color: #000;
            transform: translateY(-50%);
        }

        &[aria-expanded="true"]::after {
            transform: translateY(-50%) rotate(-180deg);
            color: rgb(231, 121, 55);
        }
    }

    .accordion-body {
        padding: 1.5rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #555;
    }
}
.accordion .accordion-item .accordion-button {
    background-color: transparent;
    border: none; 
  }
  
  .accordion .accordion-item .accordion-button:not(.collapsed) {
    background-color: transparent; 
    box-shadow: none; 
    color: rgb(231, 121, 55);
  }
  .accordion .accordion-item .accordion-button:focus {
    box-shadow: none; 
  }
  
.icon-container {
    min-width: 2.5rem;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.125rem;
    background: rgba(29, 75, 64, 0.1);
    border-radius: 50%;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
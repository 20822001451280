.home {
  background-image: url('../img/h1.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 50px 20px;
  opacity: 0.88;
}

.home-container {
  padding: 50px 20px;
}

h1 {
  font-size: 2.5rem;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 30px;
}

.custom-card {
  background-color: #ffffff;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
}

.custom-card .form-control {
  border-radius: 5px;
  border: 1px solid #ced4da;
  padding: 10px;
  font-size: 1rem;
}

.custom-card .btn-success {
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.custom-card .btn-success:hover {
  background-color: #218838;
}


.home ul li{
  color: #ffffff;
  list-style: none;
  font-size: 18px;
}
.hero-btn2{
  background: rgb(231, 121, 55);
  border: none;
  color: white;
  margin-left: 2rem;
  padding: 6px 10px;
  height: "2rem";
  width: "7rem";
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.hero-btn2:hover {
  transform: scale(1.1); 
  background: rgb(255, 140, 75); 
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.3);
}
.hero-btn1{  
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.hero-btn1:hover {
  transform: scale(1.1);  
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.3); 
}